import "../../styles/Devoirs.css";
import { clone } from "../../utils/fonctions";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Chapitres from "./Chapitres";
import Select from "../Select";

export default function DevoirHeader({ devoir = undefined, onChange = undefined }) {
  const sources = useSelector((state) => state.devoirs.devoirSources);
  const [sujet, setSujet] = useState("");
  const [source, setSource] = useState("");
  const [points, setPoints] = useState(0);
  const [moyenne, setMoyene] = useState(0);
  const [coeff, setCoeff] = useState(0);
  const [verrouille, setVerrouille] = useState(false);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    setSujet(devoir?.sujet);
    setSource(devoir?.source);
    setPoints(devoir?.noteDS);
    setVerrouille(devoir?.verrouille);
    setQuestions(devoir?.questions);
    setMoyene(devoir?.moyenne);
    setCoeff(devoir?.coeff);
  }, [devoir]);

  function changeSource(source) {
    var devoirCopy = clone(devoir);
    devoirCopy.source = source;
    onChange?.(devoirCopy);
  }

  function changeSujet(sujet) {
    var devoirCopy = clone(devoir);
    devoirCopy.sujet = sujet;
    onChange?.(devoirCopy);
  }

  function changeVerrouille() {
    var devoirCopy = clone(devoir);
    devoirCopy.verrouille = !devoirCopy.verrouille;
    onChange?.(devoirCopy);
  }

  return (
    <>
      <div className="columns">
        <div className="column col-1 text-center mt-1">
          <span className="titreDevoir">Sujet/Titre</span>
        </div>
        <div className="column col-5">
          <div className="input-group">
            <input className="form-input" type="text" value={sujet} onChange={(e) => changeSujet(e.target.value)} />
          </div>
        </div>
        <div className="column col-1 text-center mt-1">
          <span className="titreDevoir">Source</span>
        </div>
        <div className="column col-5">
          <Select
            elements={sources}
            select={source}
            emptyElement={true}
            emptyElementLabel={"Choisir une source"}
            onSelectChange={(c) => changeSource(c)}
            keySelector={(c) => c.key}
            valueSelector={(c) => c.key}
            labelSelector={(c) => c.value}
          />
        </div>
      </div>
      <div className="columns">
      <div className="column col-1 text-center mt-1">
          <span className="titreDevoir">Points</span>
        </div>
        <div className="column col-1 text-center mt-1">
          <span>{points}</span>
        </div>
        <div className="column col-1 text-center mt-1">
          <span className="titreDevoir">Coeff</span>
        </div>
        <div className="column col-1 text-center mt-1">
          <span>{coeff}</span>
        </div>
        <div className="column col-1 text-center mt-1">
          <span className="titreDevoir">Moyenne</span>
        </div>
        <div className="column col-1 text-center mt-1">
          <span>{moyenne}</span>
        </div>
        <div className="column col"/>
        <div className="column col-auto">
          <div className="form-group tooltip tooltip-top" data-tooltip="Vérouiller le devoir pour les corrections">
            <label className="form-switch">
              <input type="checkbox" checked={verrouille} onChange={() => changeVerrouille()} />
              <i className="form-icon"></i><span className="titreDevoir">Vérrouillé</span>
            </label>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column col-1 text-center mt-1">
          <span className="titreDevoir">Chapitres</span>
        </div>
        <div className="column col-9 mt-1">
          <Chapitres questions={questions} />
        </div>
      </div>
    </>
  );
}

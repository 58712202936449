import "../../styles/Utilisateur.css";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectAllDevoirs, getDevoirs, getCorrige, getSujet } from "../../store/devoirs";
import Devoir from "../../models/Devoir";
import UtilisateurTemplate from "./UtilisateurTemplate";
import List from "../List";
import ScrollView from "../ScrollView";

export default function UtilisateurBiblio() {
  const [selectedDevoir, setSelectedDevoir] = useState();
  const [devoirsValides, setDevoirsValides] = useState();
  const devoirs = useSelector(selectAllDevoirs);
  const devoirsStatus = useSelector((state) => state.devoirs.status);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  function devoirValidePourClasse(devoirs, user) {
    const derniereClasseEleve = user.groupes.reduce((a, g) => g.annee > a.annee ? g : a, { annee: 0 });
    if (!derniereClasseEleve || derniereClasseEleve.length === 0) {
      return false;
    }
    const classesValides = [];
    if (derniereClasseEleve.classe === "MP") {
      classesValides.push("MP");
    }
    if (derniereClasseEleve.classe.substring(0, 2) === "MP") {
      classesValides.push("MPSI");
    }
    if (derniereClasseEleve.classe === "PCSI") {
      classesValides.push("PCSI");
    }
    
    return devoirs.filter(devoir => {
      const classeValide = classesValides.some((c) => devoir.classe.includes(c));
      const anneeValide = derniereClasseEleve.annee - 1 <= devoir.annee;
      return classeValide && anneeValide;
    }).map(d => new Devoir(d));
  }

  async function sujetDevoir() {
    if (selectedDevoir) {
      await dispatch(getSujet(selectedDevoir)).unwrap();
    }
  }

  async function corrigeDevoir() {
    if (selectedDevoir) {
      await dispatch(getCorrige(selectedDevoir)).unwrap();
    }
  }

  useEffect(() => {
    if (devoirsStatus === "idle") {
      dispatch(getDevoirs());
    }
    if (devoirs && devoirs.length > 0) {
      const devoirsValides = devoirValidePourClasse(devoirs, user);
      devoirsValides.sort(Devoir.sort);
      setDevoirsValides(devoirsValides, user);
    }
  }, [dispatch, devoirsStatus, devoirs, user]);

  async function changeSelectedDevoir(e, devoir) {
    e.preventDefault();
    setSelectedDevoir(devoir);
  }

  return (
    <UtilisateurTemplate>
      <ScrollView maxHeight="calc(100vh - 250px)" margin="0 0 0 0">
        <List elements={devoirsValides} keyPrefix="devoir">
          {(d) => (
            <a href={"#" + d.date} onClick={(e) => changeSelectedDevoir(e, d)} className={d.date === selectedDevoir?.date ? "active" : ""}>
              <div className="container">
                <div className="columns">
                  <div className="column col">
                    <div>{d.nom}</div>
                  </div>
                  <div className="column col-auto">
                    <button className="btn longButton" onClick={() => sujetDevoir()}>
                      Sujet
                    </button>
                  </div>
                  <div className="column col-auto">
                    <button className="btn longButton" onClick={() => corrigeDevoir()}>
                      Corrigé
                    </button>
                  </div>
                </div>
              </div>
            </a>
          )}
        </List>
      </ScrollView>
    </UtilisateurTemplate>
  );
}

import "../../styles/Admin.css";
import Devoir from "../../models/Devoir";
import AdminTemplate from "./AdminTemplate";
import Select from "../Select";
import List from "../List";
import DevoirItem from "../Devoirs/DevoirItem";
import Accordion from "../Accordion";
import ScrollView from "../ScrollView";
import Modal from "../Modal";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectAllEleves, selectAllClasses, getEleves } from "../../store/eleves";
import { selectAllDevoirs, getDevoirs, addDevoir } from "../../store/devoirs";
import { selectAllGroupes, getGroupes } from "../../store/groupes";
import { clone } from "../../utils/fonctions"

var today = new Date();
const dateStr =
  today.getFullYear() -
  2000 +
  (today.getMonth() + 1).toString().padStart(2, "0") +
  today.getDate().toString().padStart(2, "0");

export default function AdminDevoirs() {
  const [selectedClasse, setSelectedClasse] = useState("");
  const [elevesClasse, setElevesClasse] = useState([]);
  const [selectedDevoirId, setSelectedDevoirId] = useState("");
  const [selectedDevoir, setSelectedDevoir] = useState();
  const [sortDevoirs, setSortDevoirs] = useState();
  const [forceCopie, setForceCopie] = useState(false);
  const [dateCopieDevoir, setDateCopieDevoir] = useState(dateStr);
  const [modalCopieDocumentActive, setModalCopieDocumentActive] =
    useState(false);

  const dispatch = useDispatch();
  const eleves = useSelector(selectAllEleves);
  const devoirs = useSelector(selectAllDevoirs);
  const groupes = useSelector(selectAllGroupes);
  const elevesStatus = useSelector((state) => state.eleves.status);
  const devoirsStatus = useSelector((state) => state.devoirs.status);
  const groupesStatus = useSelector((state) => state.eleves.status);

  function classeDeDevoir(groupe, classe) {
    if (groupe.classe !== classe) {
      return false;
    }
    return groupe.annee === new Date().getFullYear();
  }

  const classes = ["MP", "MPSI", "PCSI", "1"];

  useEffect(() => {
    if (groupesStatus === "idle") {
      dispatch(getGroupes());
    }
    if (elevesStatus === "idle") {
      dispatch(getEleves());
    }
    if (devoirsStatus === "idle") {
      dispatch(getDevoirs());
    }
    if (devoirs) {
      const sortDevoirs = devoirs.map(d => new Devoir(d)).sort(Devoir.sort);
      setSortDevoirs(sortDevoirs);
    }
  }, [dispatch, elevesStatus, devoirsStatus, groupesStatus, devoirs]);

  function changeSelectDevoir(devoirId) {
    const devoir = devoirs.filter((d) => d._id === devoirId).shift();
    if (devoir) {
      setSelectedDevoir(new Devoir(devoir));
    }
    setSelectedDevoirId(devoirId);
  }

  function changeDevoir(devoir) {
    setSelectedDevoir(new Devoir(devoir));
  }

  function changeClasse(classe) {
    setSelectedClasse(classe);
    const elevesClasse = eleves.filter(
      (e) => e.groupes.findIndex((c) => classeDeDevoir(c, classe)) >= 0
    );
    setElevesClasse(elevesClasse);
  }

  function nouveau() {
    setSelectedDevoirId("");
    var devoir = new Devoir({ date: dateStr });
    devoir.classe = selectedClasse;
    setSelectedDevoir(devoir);
  }

  function sujetDevoir() {
    if (selectedDevoir) {
      window.open(
        `/api/devoirs/${selectedDevoir.date}/sujet`
      );
    }
  }

  function corrigeDevoir() {
    if (selectedDevoir) {
      window.open(
        `/api/devoirs/${selectedDevoir.date}/corrige`
      );
    }
  }

  function changeDateNouveauDevoir(value) {
    setDateCopieDevoir(value);
    selectedDevoir.date = value;
  }

  function sauvegarder(value) {
    setDateCopieDevoir(dateStr);
    setForceCopie(false);
    setModalCopieDocumentActive(value);
  }

  function validerSauvegarde() {
    if (
      !dateCopieDevoir ||
      dateCopieDevoir.length !== 6 ||
      isNaN(parseInt(dateCopieDevoir))
    ) {
      // erreur
    }
    const update = selectedDevoir._id !== "" &&
      selectedDevoir.classe === selectedClasse;
    const devoir = new Devoir(selectedDevoir);
    if (!update) {
      devoir.date = dateCopieDevoir;
    }
    devoir.classe = selectedClasse;
    dispatch(addDevoir(devoir));
    setModalCopieDocumentActive(false);
  }

  return (
    <AdminTemplate>
      <div className="container">
        <div className="columns">
          <div className="column col-12 col-md-12">
            <Accordion
              title={"Classe " + selectedClasse}
              keySuffix="Classe"
              defaultChecked={true}
            >
              <Select
                elements={classes}
                select={selectedClasse}
                emptyElement={true}
                emptyElementLabel={"Choisir une classe"}
                onSelectChange={(c) => changeClasse(c)}
                keySelector={(c) => c}
                valueSelector={(c) => c}
                labelSelector={(c) => c}
              />
              <ScrollView maxHeight="500px" margin="10 0 0 0">
                <List elements={elevesClasse} keyPrefix={"eleve"}>
                  {(c) => (
                    <div className="eleve">
                      {c.nom} {c.prenom}
                    </div>
                  )}
                </List>
              </ScrollView>
            </Accordion>
          </div>
          {selectedClasse && (
            <>
              <div className="column col-2 col-md-12">
                <button
                  className="btn btn-success longButton tooltip tooltip-bottom"
                  data-tooltip="Nouveau devoir"
                  onClick={() => nouveau()}
                >
                  <i className="icon icon-plus"></i> Nouveau
                </button>
              </div>
              <div className="column col-6 col-md-12">
                <Select
                  elements={sortDevoirs}
                  select={selectedDevoirId}
                  emptyElement={true}
                  emptyElementLabel={"Choisir un devoir existant"}
                  onSelectChange={(d) => changeSelectDevoir(d)}
                  keySelector={(c) => c._id}
                  valueSelector={(c) => c._id}
                  labelSelector={(c) => c.nom}
                />
              </div>
              <div className="column col-2 col-md-12">
                <button
                  disabled={!selectedDevoirId}
                  className="btn longButton"
                  onClick={() => sujetDevoir()}
                >
                  Sujet
                </button>
              </div>
              <div className="column col-2 col-md-12">
                <button
                  disabled={!selectedDevoirId}
                  className="btn longButton"
                  onClick={() => corrigeDevoir()}
                >
                  Corrigé
                </button>
              </div>
              <div className="column col-12 col-md-12 pt-2">
                <DevoirItem
                  devoirInitial={selectedDevoir}
                  onChange={changeDevoir}
                ></DevoirItem>
              </div>
              <div className="column col-12 col-md-12">
                <button
                  disabled={!selectedDevoir || selectedDevoir.sujet === ""}
                  className="btn btn-primary longButton mb-2 tooltip tooltip-top"
                  data-tooltip="Sauvegarder le devoir"
                  onClick={() => sauvegarder(true)}
                >
                  Sauvegarder
                </button>
              </div>
            </>
          )}
        </div>
        <Modal
          title="Sauvegarder le devoir"
          keySuffix="copieModal"
          active={modalCopieDocumentActive}
          onCancel={() => sauvegarder(false)}
          onValidate={() => validerSauvegarde()}
        >
          <div className="container">
            <div className="columns">
              {selectedDevoir && selectedDevoir._id === "" && (
                <>
                  <div className="column col-12 mb-2">
                    <div className="form-group">
                      <label className="form-label" htmlFor="input-dateCopie">
                        Date au format AAMMJJ
                      </label>
                      <input
                        className="form-input"
                        type="text"
                        id="input-dateCopie"
                        placeholder="AAMMJJ"
                        value={dateCopieDevoir}
                        onChange={(e) =>
                          changeDateNouveauDevoir(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="column col-12 mt-2">
                    Sauvegarder le nouveau devoir{" "}
                    <span className="bg-secondary text-primary px-2">
                      {selectedDevoir?.nom}
                    </span>
                  </div>
                </>
              )}
              {selectedDevoir &&
                selectedDevoir._id !== "" &&
                selectedDevoir.classe === selectedClasse && (
                  <>
                    <div className="column col-12 mb-2">
                      <div className="form-group">
                        <label className="form-checkbox">
                          <input
                            type="checkbox"
                            checked={forceCopie}
                            onChange={() => setForceCopie(!forceCopie)}
                          />
                          <i className="form-icon"></i>Copier le devoir sur une
                          nouvelle date
                        </label>
                      </div>
                      <div className="form-group">
                        <label className="form-label" htmlFor="input-dateCopie">
                          Date au format AAMMJJ
                        </label>
                        <input
                          disabled={!forceCopie}
                          className="form-input"
                          type="text"
                          id="input-dateCopie"
                          placeholder="AAMMJJ"
                          value={dateCopieDevoir}
                          onChange={(e) => setDateCopieDevoir(e.target.value)}
                        />
                      </div>
                    </div>
                    {forceCopie && (
                      <div className="column col-12 mt-2">
                        Copie du devoir{" "}
                        <span className="bg-secondary text-primary px-2">
                          {selectedDevoir?.nom}
                        </span>{" "}
                        pour la classe{" "}
                        <span className="bg-secondary text-primary">
                          {selectedClasse}
                        </span>{" "}
                        à la date du{" "}
                        <span className="bg-secondary text-primary">
                          {dateCopieDevoir}
                        </span>{" "}
                      </div>
                    )}
                    {!forceCopie && (
                      <div className="column col-12 mt-2">
                        Mise à jour du devoir{" "}
                        <span className="bg-secondary text-primary px-2">
                          {selectedDevoir?.nom}
                        </span>
                      </div>
                    )}
                  </>
                )}
              {selectedDevoir &&
                selectedDevoir._id !== "" &&
                selectedDevoir.classe !== selectedClasse && (
                  <>
                    <div className="column col-12 mb-2">
                      <label className="form-label" htmlFor="input-dateCopie">
                        Date au format AAMMJJ
                      </label>
                      <div className="form-group">
                        <input
                          className="form-input"
                          type="text"
                          id="input-dateCopie"
                          placeholder="AAMMJJ"
                          value={dateCopieDevoir}
                          onChange={(e) => setDateCopieDevoir(e.target.value)}
                        />
                      </div>
                    </div>
                      <div className="column col-12 mt-2">
                        Copie du devoir{" "}
                        <span className="bg-secondary text-primary px-2">
                          {selectedDevoir?.nom}
                        </span>{" "}
                        pour la classe{" "}
                        <span className="bg-secondary text-primary">
                          {selectedClasse}
                        </span>{" "}
                        à la date du{" "}
                        <span className="bg-secondary text-primary">
                          {dateCopieDevoir}
                        </span>{" "}
                      </div>
                  </>
                )}
            </div>
          </div>
        </Modal>
      </div>
    </AdminTemplate>
  );
}

import QuestionDevoir from "./QuestionDevoir";

export default class Devoir {
  _id = "";
  date = "";
  annee = "";
  classe = "";
  matiere = "";
  sujet = "";
  source = "";
  noteDS = 0;
  coeff = 1;
  moyenne = 1;
  verrouille = false;
  questions = [];

  constructor({
    _id = "",
    date = "",
    annee = "",
    classe = "",
    matiere = "",
    sujet = "",
    source = "",
    noteDS = 0,
    coeff = 1,
    moyenne = 0,
    verrouille = false,
    questions = [],
  }) {
    this._id = _id;
    this.date = date;
    this.annee = annee;
    this.classe = classe;
    this.matiere = matiere;
    this.sujet = sujet;
    this.source = source;
    this.noteDS = noteDS;
    this.coeff = coeff;
    this.moyenne = moyenne;
    this.verrouille = verrouille;
    this.questions = questions.map(q => new QuestionDevoir(q));
  }
  
  get nom() {
    return `${this.date} - ${this.classe}_${this.matiere} - ${this.sujet}`;
  }

  static sort(a, b) {
    const diff = a.classe.localeCompare(b.classe);
    return diff === 0 ? -a.date.localeCompare(b.date) : diff;
  }
}


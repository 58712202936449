import * as React from "react";
import { BrowserRouter, Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import { MathJaxContext } from 'better-react-mathjax';
import Menu from "./Menu";
import Footer from "./Footer";
import Accueil from "./Accueil";
import Contact from "./Contact";
import Python from "./Python";
import Login from "./Login";
import Cours from "./Cours";
import Administration from "./Administration/Administration";
import AdminEleves from "./Administration/AdminEleves";
import AdminGroupes from "./Administration/AdminGroupes";
import AdminDevoirs from "./Administration/AdminDevoirs";
import AdminEvaluation from "./Administration/AdminEvaluation";
import AdminOutils from "./Administration/AdminOutils";

import Documents from "./Documents";

import Utilisateur from "./Utilisateur/Utilisateur";
import UtilisateurCompetences from "./Utilisateur/UtilisateurCompetences";
import UtilisateurDevoirs from "./Utilisateur/UtilisateurDevoirs";
import UtilisateurBiblio from "./Utilisateur/UtilisateurBiblio";

import "../styles/App.css";

export default function App() {
  return (
    <MathJaxContext>
      <div className="container">
        <BrowserRouter>
          <Menu />
          <div className="app">
            <Routes>
              <Route path="/" element={<Accueil />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/login" element={<Login />} />
              <Route path="/python" element={<Python />} />
              <Route path="/documents" element={<Documents />} />
              <Route path="/cours" element={<Cours />}>
                <Route path=":matiere/:idCours" element={<Cours />} />
              </Route>
              <Route path="/administration" element={<Administration />} />
              <Route path="/administration/eleves" element={<AdminEleves />} />
              <Route path="/administration/groupes" element={<AdminGroupes />} />
              <Route path="/administration/devoirs" element={<AdminDevoirs />} />
              <Route path="/administration/evaluer" element={<AdminEvaluation />} />
              <Route path="/administration/outils" element={<AdminOutils />} />
              <Route path="/utilisateur" element={<Utilisateur />} />
              <Route path="/utilisateur/competences" element={<UtilisateurCompetences />} />
              <Route path="/utilisateur/devoirs" element={<UtilisateurDevoirs />} />
              <Route path="/utilisateur/bibliotheque" element={<UtilisateurBiblio />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </div>
          <Footer />
        </BrowserRouter>
      </div>
    </MathJaxContext>
  );
}

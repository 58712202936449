import { NavLink } from "react-router-dom";

const menuItems = [
  {
    to: "/utilisateur/competences",
    name: "Compétences",
    tooltip: "Suivi par compétence",
    tooltipDirection: "right",
    icon: "home-fill",
  },
  {
    to: "/utilisateur/devoirs",
    name: "Devoirs",
    tooltip: "Suivi des devoirs",
    tooltipDirection: "right",
    icon: "home-fill",
  },
  {
    to: "/utilisateur/bibliotheque",
    name: "Bibliothèque",
    tooltip: "Bibliothèque de devoirs",
    tooltipDirection: "right",
    icon: "home-fill",
  },
];

export default function UtilisateurMenu() {
  function menuItem(param) {
    return (
      <NavLink
        to={param.to}
        className={({ isActive, isPending }) =>
          isActive
            ? "bg-primary text-light p-2 tooltip tooltip-" + param.tooltipDirection
            : "p-2 tooltip tooltip-" + param.tooltipDirection
        }
        data-tooltip={param.tooltip}
      >
        {param.name}
      </NavLink>
    );
  }

  return (
    <>
    <ul className="nav hide-sm">
      {menuItems.map((m) => (
        <li key={m.name} className="nav-item">
          {menuItem(m)}
        </li>
      ))}
    </ul>
    <header className="navbar show-sm">
      <section className="navbar-section">
        {menuItems.map((m) => (
          <div key={m.name} className="m-2">
            {menuItem(m)}
          </div>
        ))}
      </section>
    </header>
    </>
  );
}
